import './singleProduct.css'

import React, { FunctionComponent, useContext } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { CheckoutContext } from '../context/CheckoutContext'
import FeatureList from './Features'

type SingleProductProps = {
  handleOpen: () => void
  setSelectedProduct: (data: any) => void
  product: any
}

const SingleProduct: FunctionComponent<SingleProductProps> = ({
  handleOpen,
  setSelectedProduct,
  product,
}) => {
  const navigate = useNavigate()
  const { additionalProducts, setAdditionalProducts, additionalPrice } =
    useContext(CheckoutContext)
  const handleChoose = () => {
    if (product.productType === 'main') {
      navigate(`/checkout/${product._id}`)
    } else {
      setAdditionalProducts([
        ...additionalProducts,
        { id: product._id, name: product.name, price: product.price },
      ])
    }
  }
  return (
    <div className='productWrapper'>
      <div className='product-heading'>
        <div className='product-tag-wrapper flex'>
          {product?.recommended && (
            <span className='product-tag recommended-tag'>
              {product?.recommended === 'recommended'
                ? 'Our recommendation'
                : 'Most Popular'}
            </span>
          )}
          {product?.finance && product?.finance > 0 && (
            <span className='product-tag finance-tag'>Finance Available</span>
          )}
        </div>
        <h2 className='mb-4'>{product.name}</h2>
        <div className='product-desc-wrapper d-flex'>
          <div className='img-wrapper mb-3'>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}${product.image}`}
              alt=''
              className='img-fluid'
            />
          </div>
          <div className='mb-4'>
            <span className='price'>
              &pound;{' '}
              {product.productType === 'main' && additionalPrice
                ? (additionalPrice + product.price).toFixed(2)
                : product.price.toFixed(2)}
            </span>
            <h5>Fully Installed inc VAT</h5>
            {product?.features && <FeatureList features={product?.features} />}
            {product?.finance && (
              <p className='flexible-cost'>
                Spread the cost with flexible finance
                <br />
                <span>from &pound;{product?.finance}*</span> per month
              </p>
            )}
            <p className='finance-info'>
              Finance applicaion subject to affordability, age and status,
              minimum spend applies.
            </p>
          </div>
        </div>
        <div className='product-btn-wrapper d-flex mt-auto'>
          <button
            className='more-info'
            onClick={() => {
              handleOpen()
              setSelectedProduct(product)
            }}
          >
            More info &gt;
          </button>
          <Button className='choose' onClick={handleChoose}>
            {product.productType === 'main' ? 'Select This Boiler' : 'Choose'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SingleProduct
