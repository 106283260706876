import React from 'react'

const FeatureList = ({ features }: { features: string }) => {
  // Parse the HTML string into DOM elements
  const parser = new DOMParser()
  const htmlDoc = parser.parseFromString(features, 'text/html')
  const paragraphs = Array.from(htmlDoc.getElementsByTagName('p'))

  return (
    <div>
      {paragraphs.map((p, index) => (
        <div
          className='feature-item d-flex align-items-start gap-2 mb-2'
          key={`feature-${index}`}
        >
          <svg
            className='flex-shrink-0 mt-1'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            width={15}
            height={15}
          >
            <circle cx='12' cy='12' r='11' fill='#28a745' />
            <path
              d='M7 12.5L10.5 16L17 9'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <p className='feature-text m-0'>{p?.textContent}</p>
        </div>
      ))}
    </div>
  )
}

export default FeatureList
