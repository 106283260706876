import { useQueryClient } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'

import Result from '../components/Result'
import Sidebar from '../components/Sidebar'
import SingleProduct from '../components/SingleProduct'
import { CheckoutContext } from '../context/CheckoutContext'
import { useGetProducts } from '../queries/products'
import Boiler from './Boiler'

const ProductListing = (props?: any) => {
  const [boilerType, setBoilerType] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [openAdditionalInfo, setOpenAdditionalInfo] = useState<boolean>(false)
  const [products, setProducts] = useState<any[]>([])
  const [powerOutput, setPowerOutput] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const queryClient = useQueryClient()
  const { quiz, setAdditionalPrice } = useContext(CheckoutContext)
  const location = useLocation()
  const navigate = useNavigate()

  const { data, isLoading } = useGetProducts(props.productType || 'main')

  useEffect(() => {
    queryClient.invalidateQueries()
  }, [])

  useEffect(() => {
    if (data) {
      setProducts(data.data.data)
    }
  }, [data])

  useEffect(() => {
    if (!quiz.length && location.pathname === '/products') {
      navigate('/')
    }
    if (quiz.length && location.pathname === '/products') {
      let additionalP = 0
      // selected answers
      const selectedAnswerQ3 = quiz.filter(
        (Each: { question: string; answer: string }) => {
          if (Each.question === 'What type of boiler do you currently have?')
            return Each.answer
        }
      )
      const selectedAnswerQ5 = quiz.filter(
        (Each: { question: string; answer: string }) => {
          if (
            Each.question ===
            'What type of boiler do you want to replace it with?'
          )
            return Each.answer
        }
      )
      const selectedAnswerQ8 = quiz.filter(
        (Each: { question: string; answer: string }) => {
          if (Each.question === 'Where do you want your new boiler to be?')
            return Each.answer
        }
      )
      const selectedAnswerQ9 = quiz.filter(
        (Each: { question: string; answer: string }) => {
          if (Each.question === 'Where would you like your new boiler to be?')
            return Each.answer
        }
      )
      const selectedAnswerQ18 = quiz.filter(
        (Each: { question: string; answer: string }) => {
          if (Each.question === 'Where does your flue exit your home?')
            return Each.answer
        }
      )
      // adding amount according to answers
      if (
        selectedAnswerQ3.length &&
        selectedAnswerQ3[0].answer === 'Back Boiler'
      ) {
        additionalP += 1000
      }
      if (
        selectedAnswerQ8.length &&
        (selectedAnswerQ8[0].answer === 'Conversion to airing cupboard' ||
          selectedAnswerQ8[0].answer === 'Utility Room' ||
          selectedAnswerQ8[0].answer === 'Kitchen' ||
          selectedAnswerQ8[0].answer === 'Garage' ||
          selectedAnswerQ8[0].answer === 'Bathroom' ||
          selectedAnswerQ8[0].answer === 'Bedroom' ||
          selectedAnswerQ8[0].answer === 'Loft' ||
          selectedAnswerQ8[0].answer === 'Other')
      ) {
        additionalP += 600
      }
      if (
        ((selectedAnswerQ3.length &&
          selectedAnswerQ5.length &&
          selectedAnswerQ3[0].answer === 'Regular') ||
          selectedAnswerQ3[0].answer === 'System') &&
        selectedAnswerQ5[0].answer === 'Combi'
      ) {
        additionalP += 1000
      }
      if (
        selectedAnswerQ3.length &&
        selectedAnswerQ9.length &&
        selectedAnswerQ3[0].answer !== 'Back Boiler' &&
        selectedAnswerQ9[0].answer === 'Same room'
      ) {
        additionalP += 600
      }
      if (
        selectedAnswerQ3.length &&
        selectedAnswerQ9.length &&
        selectedAnswerQ3[0].answer !== 'Back Boiler' &&
        selectedAnswerQ9[0].answer === 'Same floor'
      ) {
        additionalP += 800
      }
      if (
        selectedAnswerQ3.length &&
        selectedAnswerQ9.length &&
        selectedAnswerQ3[0].answer !== 'Back Boiler' &&
        selectedAnswerQ9[0].answer === 'Another floor'
      ) {
        additionalP += 900
      }
      if (selectedAnswerQ18.length && selectedAnswerQ18[0].answer === 'Roof') {
        additionalP += 500
      }
      setAdditionalPrice(additionalP)
      const newBoiler = quiz.find(
        (item) =>
          item.question ===
          'What type of boiler do you want to replace it with?'
      )
      const bathtubs = quiz.find(
        (item) => item.question === 'How many bathtubs do you have?'
      )
      const showers = quiz.find(
        (item) => item.question === 'How many separate showers?'
      )
      setBoilerType(newBoiler?.answer || '')
      if (
        newBoiler?.answer.toLowerCase() === 'combi' &&
        (bathtubs?.answer === '2' ||
          bathtubs?.answer === '3+' ||
          showers?.answer === '2+')
      ) {
        setPowerOutput(true)
      } else {
        setPowerOutput(false)
      }
    }
  }, [quiz, location])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleAdditionalInfo = () => {
    setOpenAdditionalInfo(false)
  }

  // const handleClose = (): void => {
  //   setOpen(false)
  // }

  const handleOpenClose = () => {
    setOpen(!open)
  }

  const handleSortByPriceAscending = () => {
    const sortedProducts = [...products].sort((a, b) => a.price - b.price)
    setProducts(sortedProducts)
  }

  const handleSortByPriceDescending = () => {
    const sortedProducts = [...products].sort((a, b) => b.price - a.price)
    setProducts(sortedProducts)
  }

  const handleSortByTitleAscending = () => {
    const sortedProducts = [...products].sort((a, b) =>
      a.name.localeCompare(b.name)
    )
    setProducts(sortedProducts)
  }

  const handleSortByTitleDescending = () => {
    const sortedProducts = [...products].sort((a, b) =>
      b.name.localeCompare(a.name)
    )
    setProducts(sortedProducts)
  }

  const handleFilterByFuel = (fuel: string) => {
    const filteredProducts =
      fuel === 'none'
        ? data?.data.data
        : data?.data.data.filter((product: any) => product?.brand === fuel)
    setProducts(filteredProducts || [])
  }

  return (
    <>
      {!props.productType && (
        <>
          <Boiler step={1} totalStep={5} />
          <Result
            result={
              // eslint-disable-next-line eqeqeq
              powerOutput
                ? products.filter(
                    (product) =>
                      (product.boiler_type as string).toLowerCase() ===
                        boilerType.toLowerCase() &&
                      Number(product.hot_water_output || 0) >= 30
                  ).length || 0
                : products.filter(
                    (product) =>
                      (product.boiler_type as string).toLowerCase() ===
                      boilerType.toLowerCase()
                  ).length || 0
            }
            handleSortByPriceAscending={handleSortByPriceAscending}
            handleSortByPriceDescending={handleSortByPriceDescending}
            handleSortByTitleAscending={handleSortByTitleAscending}
            handleSortByTitleDescending={handleSortByTitleDescending}
            handleFilterByFuel={handleFilterByFuel}
          />
        </>
      )}
      {isLoading && (
        <div className='d-flex justify-content-center py-5'>
          <div className='spinner-border text-warning' role='status'></div>
        </div>
      )}
      {!props.productType ? (
        <Container>
          <Row>
            <Col md={props.productType === 'additional' ? 9 : 12}>
              <div className='productListWrapper py-5'>
                {powerOutput
                  ? products
                      ?.filter(
                        (product) =>
                          (product.boiler_type as string).toLowerCase() ===
                            boilerType.toLowerCase() &&
                          Number(product.hot_water_output || 0) >= 30
                      )
                      .map((product, index) => {
                        return (
                          <SingleProduct
                            product={product}
                            handleOpen={handleOpen}
                            setSelectedProduct={setSelectedProduct}
                            key={index}
                          />
                        )
                      })
                  : products
                      ?.filter(
                        (product) =>
                          (product.boiler_type as string).toLowerCase() ===
                          boilerType.toLowerCase()
                      )
                      .map((product, index) => {
                        return (
                          <SingleProduct
                            product={product}
                            handleOpen={handleOpen}
                            setSelectedProduct={setSelectedProduct}
                            key={index}
                          />
                        )
                      })}
              </div>
            </Col>
            {props.productType === 'additional' ? (
              <Col md={3}>
                <div
                  className='order-sidebar text-center mt-5'
                  style={{ minHeight: '50vh' }}
                >
                  <h3 className='text-center mb-4'>Summary</h3>
                  <Button
                    variant='text'
                    onClick={() => setOpenAdditionalInfo(true)}
                  >
                    What&apos;s Included &gt;
                  </Button>
                </div>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Container>
      ) : (
        <div className='productListWrapper'>
          {products?.map((product, index) => {
            return (
              <SingleProduct
                product={product}
                handleOpen={handleOpen}
                setSelectedProduct={setSelectedProduct}
                key={index}
              />
            )
          })}
        </div>
      )}
      <Sidebar
        open={open}
        needHelp={false}
        product={selectedProduct}
        handleClose={handleOpenClose}
      />
      <Sidebar
        open={openAdditionalInfo}
        needHelp={false}
        isAdditionalInfo
        handleClose={handleAdditionalInfo}
      />
    </>
  )
}
export default ProductListing
